<template>
  <div :class="browserDefault == 'normal'? 'px-0 background-landing-page-default' : browserDefault == 'so-so normal'? 'px-0 background-landing-page' : 'px-0 background-landing-page-blackright'">
    
    <v-app-bar
    elevation="0"
    class="header-main"
    >

      <!-- <v-img
        max-height="60"
        max-width="60"
        class="mr-3"
        :src="logoPLUS"
      ></v-img> -->

      <v-img
        max-height="150"
        max-width="120"
        class="mr-3"
        :src="logoTeman"
      ></v-img>
      
      <v-toolbar-title
      v-show="$vuetify.breakpoint.lgAndUp"
      class="white--text font-weight-bold pl-16 cust-title-totalexpress"
      >
        Total Expressways Maintenance Management System
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          icon
          large
          color="white"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          >
            <v-icon>mdi-account-tie</v-icon>
          </v-btn>
        </template>
        <span>Admin</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          icon
          large
          color="white"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip> -->

      <v-btn
      v-show="$store.getters.user.role_detail == undefined? false :  $store.getters.user.role_detail.role == 'admin'? true : false"
      text
      :color="$vuetify.breakpoint.lgAndUp? 'black' : 'white'"
      class=""
      @click="goToPage('/UserManagement')"
      >
        <v-icon left>mdi-account-tie</v-icon>
        Admin
      </v-btn>

      <v-divider
      v-show="$store.getters.user.role_detail == undefined? false :  $store.getters.user.role_detail.role == 'admin'? true : false"
      class="mx-0"
      vertical
      inset
      ></v-divider>

      <v-btn
      text
      :color="$vuetify.breakpoint.lgAndUp? 'black' : 'white'"
      @click="signout()"
      >
        <v-icon left>mdi-logout</v-icon>
        Logout
      </v-btn>

    </v-app-bar>

    <v-row class="mx-2" style="height:calc(100% - 64px);" align="center" justify="center">

      <!-- Left Section -->
      <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="text-center">

        <v-row>

          <!-- CS -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
            <article class="card card--1">
              <div class="card__info-hover">
                <svg class="card__like"  viewBox="0 0 24 24">
                    <!-- <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /> -->
                </svg>
                <div class="card__clock-info">
                  <!-- <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">15 min</span> -->
                  <span>
                    Major highway assets e.g. Slope, Bridge, Culvert, Tunnel, Signboard, Buildings etc.
                  </span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link" style="cursor:default !important;">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <!-- <span class="card__category">Module</span> -->
                <h3 v-if="titleModules == 'ok'" class="card__title">Civil & Structure</h3>
                <h3 v-else class="card__title">Civil & <br>  Structure</h3>
                <span class="card__by">
                  <!-- click  -->
                  <a
                  v-show="loading_cs == false"
                  href="#" 
                  class="card__author"
                  :class="loading_cs == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.cs == false ? 'module-button-off' : 'module-button'"
                  @click="discoverMore('CS',loading_cs == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.cs == false ? 'module-button-off' : 'module-button')"
                  >
                    <span class="mr-2">Go To</span>
                    <v-icon small class="white--text">mdi-arrow-right</v-icon>
                  </a>
                </span>
                <v-progress-circular
                v-show="loading_cs == true"
                indeterminate
                :size="20"
                :width="3"
                color="primary"
                ></v-progress-circular>
              </div>
            </article>
          </v-col>

          <!-- WME -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
            <article class="card card--2">
              <div class="card__info-hover">
                <svg class="card__like"  viewBox="0 0 24 24">
                    <!-- <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /> -->
                </svg>
                <div class="card__clock-info">
                  <!-- <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">15 min</span> -->
                  <span>
                    Equipment e.g. VMS, Lighting System, CCTVs etc.
                  </span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link" style="cursor:default !important;">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <!-- <span class="card__category">Module</span> -->
                <h3 v-if="titleModules == 'ok'" class="card__title">Mechanical & Electrical</h3>
                <h3 v-else class="card__title">Mechanical <br> & Electrical</h3>
                <span class="card__by">
                  <!-- click  -->
                  <a
                  v-show="loading_me == false"
                  href="#" 
                  class="card__author"
                  :class="loading_me == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.wme == false ? 'module-button-off' : 'module-button'"
                  @click="discoverMore('ME',loading_me == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.wme == false ? 'module-button-off' : 'module-button')"
                  >
                    <!-- Discover More -->
                    <span class="mr-2">Go To</span>
                    <v-icon small class="white--text">mdi-arrow-right</v-icon>
                  </a>
                  <v-progress-circular
                  v-show="loading_me == true"
                  indeterminate
                  :size="20"
                  :width="3"
                  color="primary"
                  ></v-progress-circular>
                </span>
              </div>
            </article>
          </v-col>

          

        </v-row>

        <v-row>

          <!-- RF -->
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
            <article class="card card--3">
              <div class="card__info-hover">
                <svg class="card__like"  viewBox="0 0 24 24">
                    <!-- <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /> -->
                </svg>
                <div class="card__clock-info">
                  <!-- <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">15 min</span> -->
                  <span>
                    For all fixtures that provides information or safety for the highway users, e.g. Road line marking, guardrails, fencing etc.
                  </span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link" style="cursor:default !important;">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <!-- <span class="card__category">Module</span> -->
                <h3 v-if="titleModules == 'ok'" class="card__title">Road Furniture</h3>
                <h3 v-else class="card__title">Road <br> Furniture</h3>
                <span class="card__by">
                  <!-- click  -->
                  <a
                  v-show="loading_rf == false"
                  href="#" 
                  class="card__author"
                  :class="loading_rf == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.rf == false ? 'module-button-off' : 'module-button'"
                  @click="discoverMore('RF',loading_rf == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.rf == false ? 'module-button-off' : 'module-button')"
                  >
                    <!-- Discover More -->
                    <span class="mr-2">Go To</span>
                    <v-icon small class="white--text">mdi-arrow-right</v-icon>
                  </a>
                  <v-progress-circular
                  v-show="loading_rf == true"
                  indeterminate
                  :size="20"
                  :width="3"
                  color="primary"
                  ></v-progress-circular>
                </span>
              </div>
            </article>
          </v-col>

          <!-- TEMAN 2 -->
           <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
            <article class="card card--4">
              <div class="card__info-hover">
                <svg class="card__like"  viewBox="0 0 24 24">
                    <!-- <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /> -->
                </svg>
                <div class="card__clock-info">
                  <!-- <svg class="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
                  </svg>
                  <span class="card__time">15 min</span> -->
                  <span>
                    Fixtures that provides information or safety for the highway inventory assets, e.g. Road Marking, Fencing, Guard Rail and Signboard etc.
                  </span>
                </div>
              </div>
              <div class="card__img"></div>
              <a href="#" class="card_link" style="cursor:default !important;">
                <div class="card__img--hover"></div>
              </a>
              <div class="card__info">
                <!-- <span class="card__category">Module</span> -->
                <h3 v-if="titleModules == 'ok'" class="card__title">Teman 2</h3>
                <h3 v-else class="card__title">Teman <br> 2</h3>
                <span class="card__by">
                  <!-- click  -->
                  <a
                  v-show="loading_teman2 == false"
                  href="#" 
                  class="card__author"
                  :class="loading_teman2 == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.rf == false ? 'module-button-off' : 'module-button'"
                  @click="discoverMore('TEMAN2',loading_teman2 == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.rf == false ? 'module-button-off' : 'module-button')"
                  >
                    <!-- Discover More -->
                    <span class="mr-2">Go To</span>
                    <v-icon small class="white--text">mdi-arrow-right</v-icon>
                  </a>
                  <v-progress-circular
                  v-show="loading_teman2 == true"
                  indeterminate
                  :size="20"
                  :width="3"
                  color="primary"
                  ></v-progress-circular>
                </span>
              </div>
            </article>
          </v-col>

        </v-row>

      </v-col>
    
      <!-- Right Section -->
      <v-col class="text-center">
        
      </v-col>

    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
// import { bus } from '@/main';

export default {

  data(){
    return {
     
      logoPLUS: require("@/assets/img/logo/logo plus border white.png"),
      logoTeman: require("@/assets/img/logo/Teman Logo white border.png"),

      titleModules: 'ok',
      browserDefault: true,

      loading_cs: false,
      loading_me: false,
      loading_rf: false,
      loading_teman2: false,

      idleornot:  null

      
    }
  },

  mounted() {

    this.getCurrentWidthBrowser();
    this.loadLinkButtonRedirect();

    this.idleornot_listener();
    
  },

  methods: {

    getCurrentWidthBrowser(){

      let width = window.innerWidth

      if(width > 1445 ){
        this.titleModules = 'ok'
      }
      else{
        this.titleModules = 'not'
      }

      
      if(width < 1494){
        this.browserDefault = 'noooo'
      }
      else if(width < 1559){
        this.browserDefault = 'so-so normal'
      }
      else{
        this.browserDefault = 'normal'
      }

      // console.log("xxxxxxxxxx",this.browserDefault);

    },

    loadLinkButtonRedirect(){

      this.loading_cs = true;
      this.loading_me = true;
      this.loading_rf = true;
      this.loading_teman2 = true;

      setTimeout(() => {

        if(this.$store.getters.user.role_detail != undefined){

          this.loading_cs = false;
          this.loading_me = false;
          this.loading_rf = false;
          this.loading_teman2 = false;

        }

      }, 3000)

    },

    signout()
    {
      this.$store.dispatch("signOut");
    },

    discoverMore(menu,disabled)
    {

      // console.log(menu);

      if(disabled != "module-button-off"){

        if(menu == "CS")
        {
          // window.location.href ="https://teman.plus.com.my/webteman/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href ="https://donutgebu.shaz.my/webteman/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href ="https://donutgebu.shaz.my/webteman/login2";
          // window.location.href ="https://donutgebu.shaz.my/webteman/?id_token=" + this.$store.getters.user.id_token;

          if(location.protocol=='http:' && location.hostname != 'localhost')
          {
            window.location.href ="http://teman.plus.com.my/webteman/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          }
          else
          {
            window.location.href ="https://teman.plus.com.my/webteman/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          }
          
        }
        else if(menu == "ME")
        {
          window.location.href ="http://temanwme.plus.com.my/wme/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href ="https://donutgebu.shaz.my/wme/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href ="https://donutgebu.shaz.my/wme/home";
          // window.location.href ="https://donutgebu.shaz.my/wme?id_token=" + this.$store.getters.user.id_token;
        }
        else if (menu == "RF") 
        {
          window.location.href = "https://temanrf.plus.com.my/rf/index.php/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href = "https://donutgebu.shaz.my/rf/index.php/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href = "https://donutgebu.shaz.my/rf/index.php/welcome";
          // window.location.href = "https://donutgebu.shaz.my/rf/index.php/auth/login?id_token=" + this.$store.getters.user.id_token;
        }
        else if (menu == "TEMAN2") 
        {
          window.location.href = "https://teman2.serasi.tech?id_token="+this.$store.getters.user.id_token;
          // window.location.href = "https://donutgebu.shaz.my/rf/index.php/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
          // window.location.href = "https://donutgebu.shaz.my/rf/index.php/welcome";
          // window.location.href = "https://donutgebu.shaz.my/rf/index.php/auth/login?id_token=" + this.$store.getters.user.id_token;
        }

        // console.log(this.$store.getters.user.id_token);

      }


    },



    /////////////////spa stuff downhere
    /////////idle or not cek start here////////////

    idleornot_startcek()
    {
      //console.log('ada event');
      //clearInterval(this.idleornot);
      clearInterval(this.idleornot);
      this.idleornot = null;

      this.idleornot = setInterval(() => {
          this.idlefortoolong()
        }, 5 * 60 * 1000);

    },

    idlefortoolong()
    {

      alert('You will be logged out due to inactivity');
      this.$store.dispatch("signOut");
    },


    idleornot_listener()
    {


      this.$el.addEventListener('click', this.idleornot_startcek);
      this.$el.addEventListener('scroll', this.idleornot_startcek);
      this.$el.addEventListener('mousemove', this.idleornot_startcek);
      this.$el.addEventListener('keydown', this.idleornot_startcek);
      this.$el.addEventListener('keypress', this.idleornot_startcek); //outdated

    }



    /////////end of idle or not cek start here////////////



     
  },

};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.background-landing-page-default{
  background-image: url("~@/assets/img/wallpaper/nuzul-design3.jpg") !important;
  // background-color: rgba(0, 0, 0, 0.68);
  // background: white;
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.background-landing-page{
  background-image: url("~@/assets/img/wallpaper/nuzul-design-aaa.jpg") !important;
  // background-color: rgba(0, 0, 0, 0.68);
  // background: white;
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.background-landing-page-blackright{
  background-image: url("~@/assets/img/wallpaper/nuzul-design1.jpg") !important;
  // background-color: rgba(0, 0, 0, 0.68);
  // background: white;
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.header-main{
  background: #19411e00 !important;
}

.cust-title-totalexpress
{
  margin-left: 200px;
}

// Cards Styling And Animated
.cards {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    max-width: 820px;
}

.card--1 .card__img, .card--1 .card__img--hover {
    // background-image: url('https://images.pexels.com/photos/45202/brownie-dessert-cake-sweet-45202.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
    background-image: url("~@/assets/img/wallpaper/cs.png");
}

.card--2 .card__img, .card--2 .card__img--hover {
    // background-image: url('https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
    background-image: url("~@/assets/img/wallpaper/me.png");
}

.card--3 .card__img, .card--3 .card__img--hover {
    // background-image: url('https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
    background-image: url("~@/assets/img/wallpaper/rf.png");
}

.card--4 .card__img, .card--4 .card__img--hover {
    // background-image: url('https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
    background-image: url("~@/assets/img/wallpaper/teman2.png");
}

.card__like {
    width: 18px;
}

.card__clock {
    width: 15px;
  vertical-align: middle;
    fill: #AD7D52;
}
.card__time {
    font-size: 12px;
    color: #AD7D52;
    vertical-align: middle;
    margin-left: 5px;
}

.card__clock-info {
    float: right;
}

.card__img {
  visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
  
}

.card__info-hover {
    position: absolute;
    padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover {
  transition: 0.2s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  position: absolute;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
top: 0;
  
}
.card {
  margin-right: 25px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
    width: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);
}
.card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
    transform: scale(1.10, 1.10);
}

.card__info {
z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
   padding: 16px 24px 24px 24px;
}

.card__category {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
  color: #868686;
}

.card__title {
    margin-top: 5px;
    margin-bottom: 10px;
    // font-family: 'Roboto Slab', serif;
    // font-size: 0.82em;
    font-size: 1.20em;
    // white-space: nowrap;
}

.card__by {
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}

.card__author {
    font-weight: 600;
    text-decoration: none;
    // color: #AD7D52;
    color: white !important;
    padding: 5px 10px;
    border-radius: 20px;
    background: $primary;
}

.card__author:hover {
    font-weight: 600;
    text-decoration: none;
    // color: #AD7D52;
    color: white !important;
    padding: 5px 10px;
    border-radius: 20px;
    background: #387379;
}

.module-button-off{
  background: grey;
  color: rgb(209, 209, 209);
  cursor: default !important;
}
.module-button-off:hover{
  background: grey;
  color: rgb(209, 209, 209);
  cursor: default !important;
}

.card:hover .card__img--hover {
    height: 100%;
    opacity: 0.3;
}

.card:hover .card__info {
    background-color: transparent;
    position: relative;
}

.card:hover .card__info-hover {
    opacity: 1;
}


</style>


